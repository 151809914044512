.whatsapp-button {
  position: fixed !important;
  bottom: 2.5em;
  right: 2.5em;
  z-index: 20;
  background-color: var(--primary-color);
  color: white;
  width: 56px;
  height: 56px;
  border-radius: 50% !important; /* Make it perfectly circular */
  border: none;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.2s cubic-bezier(0.16, 1, 0.3, 1) !important;
  animation: pulse 2s infinite;
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
}

@media (max-width: 768px) {
  .whatsapp-button {
    bottom: 1em;
    right: 1em;
  }
}

.whatsapp-button:hover {
  transform: translateY(-2px) scale(1.03);
  box-shadow: 0 8px 24px var(--primary-color-50);
  background-color: var(--fourth-color);
}

.whatsapp-button:active {
  transform: translateY(0) scale(0.95);
  box-shadow: 0 2px 8px var(--primary-color-50);
}

.whatsapp-icon {
  width: 28px;
  height: 28px;
  filter: drop-shadow(0 1px 2px rgba(0, 0, 0, 0.1));
}

/* Subtle pulsing animation */
@keyframes pulse {
  0% {
    box-shadow: 0 0 0 0 var(--primary-color-50);
  }
  70% {
    box-shadow: 0 0 0 10px var(--primary-color-10);
  }
  100% {
    box-shadow: 0 0 0 0 var(--primary-color-10);
  }
}

/* Tooltip custom styling */
.MuiTooltip-tooltip {
  background-color: rgba(0, 0, 0, 0.8) !important;
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  font-family: "Poppins", sans-serif !important;
  font-size: 0.8rem !important;
  border-radius: 12px !important;
  padding: 8px 12px !important;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
}

.MuiTooltip-arrow {
  color: rgba(0, 0, 0, 0.8) !important;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .whatsapp-button {
    width: 50px;
    height: 50px;
    bottom: 0.8em;
    right: 0.8em;
  }

  .whatsapp-icon {
    width: 24px;
    height: 24px;
  }
}
