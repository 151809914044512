/* Provide a real background and remove overflow from the parent so the fades work properly */
.integrations-section {
  position: relative;
  padding: 4rem 0;
  /* No overflow: hidden here */
}

.logos-container {
  position: relative;
  width: 100%;
  overflow: hidden;
  z-index: 1;
}

/* Add fade overlay */
.logos-container::before,
.logos-container::after {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  width: 25%;
  z-index: 2;
  pointer-events: none;
}

.logos-container::before {
  left: 0;
  background: linear-gradient(to right, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0));
  mix-blend-mode: destination-out;
}

.logos-container::after {
  right: 0;
  background: linear-gradient(to left, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0));
  mix-blend-mode: destination-out;
}

.logos-scroll {
  display: flex;
  position: relative;
  width: max-content;
}

.logos-track {
  display: flex;
  animation: scroll 30s linear infinite;
}

.partner-logo-container {
  flex: 0 0 auto;
  height: 120px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 1.5rem;
  margin: 0 2.5rem;
  transition: all 0.4s cubic-bezier(0.16, 1, 0.3, 1);
  position: relative;
  background: rgba(255, 255, 255, 0.02);
  border-radius: 12px;
  backdrop-filter: blur(5px);
}

.partner-logo-container:hover {
  transform: translateY(-5px);
  box-shadow: 0 15px 35px rgba(0, 0, 0, 0.05);
  background: rgba(255, 255, 255, 0.06);
}

.partner-logo {
  max-width: 150px;
  max-height: 80%;
  object-fit: contain;
  transition: all 0.3s ease;
  filter: saturate(0.9);
}

.partner-logo:hover {
  filter: saturate(1);
  opacity: 1;
}

.partner-type {
  margin-top: 10px;
  font-size: 6px;
  font-weight: 500;
  text-transform: uppercase;
  padding: 3px 8px;
  border-radius: 12px;
  letter-spacing: 0.7px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.05);
  backdrop-filter: blur(10px);
}

.partner-logo-container.integración .partner-type {
  background: rgba(25, 118, 210, 0.1);
  color: rgba(25, 118, 210, 0.9);
  border: 1px solid rgba(25, 118, 210, 0.15);
}

.partner-logo-container.cliente .partner-type {
  display: none;
  background: rgba(46, 125, 50, 0.1);
  color: rgba(46, 125, 50, 0.9);
  border: 1px solid rgba(46, 125, 50, 0.15);
}

.text-container {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 2rem;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.heading-primary {
  position: relative;
}

.heading-primary::after {
  content: "";
  position: absolute;
  bottom: -14px;
  left: 50%;
  transform: translateX(-50%);
  width: 60px;
  height: 3px;
  background: linear-gradient(90deg, #2c5364 0%, #203a43 100%);
  border-radius: 2px;
}

@keyframes scroll {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-100%);
  }
}

@media (max-width: 768px) {
  .integrations-section {
    padding: 3rem 0;
  }

  .partner-logo-container {
    height: 100px;
    margin: 0 1rem;
  }

  .partner-logo {
    max-width: 120px;
  }

  .logos-container::before,
  .logos-container::after {
    width: 20%;
  }
}
